import Vue from 'vue'
import VueRouter from 'vue-router'
import Licensed from '@/Licensed'
import { authenticationGuard } from '@/auth/authenticationGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'licensed',
    component: Licensed,
    children: [
      {
        component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/List.vue'),
        path: '/dashboards',
        meta: { title: 'Dashboards' },
        name: 'DashboardList',
        children: [
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/All.vue'),
            path: '/call-summary/all',
            name: 'call-summary-all'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/ActionNeeded.vue'),
            path: '/call-summary/action-needed',
            name: 'call-summary-action-needed'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/HeadsUp.vue'),
            path: '/call-summary/heads-up',
            name: 'call-summary-heads-up'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/Highlight.vue'),
            path: '/call-summary/highlight',
            name: 'call-summary-highlight'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/NegativeFeedback.vue'),
            path: '/call-summary/negative-feedback',
            name: 'call-summary-negative-feedback'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/NoConnection.vue'),
            path: '/call-summary/no-connection',
            name: 'call-summary-no-connection'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/Appointments.vue'),
            path: '/call-summary/appointments',
            name: 'call-summary-appointments'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/RevenueOpportunity.vue'),
            path: '/call-summary/revenue-opportunity',
            name: 'call-summary-revenue-opportunity'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/ReviewsCommitted.vue'),
            path: '/call-summary/reviews-committed',
            name: 'call-summary-reviews-committed'
          },
          {
            component: () => import(/* webpackChunkName: "ShopAdd" */ './dashboards/call-summary/Voicemail.vue'),
            path: '/call-summary/voicemail',
            name: 'call-summary-voicemail'
          }
        ]
      },
      {
        component: () => import(/* webpackChunkName: "ShopAdd" */ './profiles/Id.vue'),
        path: '/profile',
        name: 'ProfileId'
      },
      {
        component: () => import(/* webpackChunkName: "ShopAdd" */ './shops/Add.vue'),
        path: '/shops/add',
        name: 'ShopAdd'
      },
      {
        component: () => import(/* webpackChunkName: "ShopEdit" */ './shops/Id.vue'),
        path: '/shops/:id',
        name: 'ShopId',
        children: [
          {
            component: () => import(/* webpackChunkName: "ShopEdit" */ './shops/Detail.vue'),
            path: 'details',
            name: 'ShopDetail'
          },
          {
            component: () => import(/* webpackChunkName: "ShopEdit" */ './shops/Financial'),
            path: 'financial',
            name: 'ShopFinancial'
          },
          {
            component: () => import(/* webpackChunkName: "ShopEdit" */ './kpi-targets/List'),
            path: 'kpi-targets',
            name: 'ShopKpiTargets'
          },
          {
            component: () => import(/* webpackChunkName: "ShopEdit" */ './shops/Team.vue'),
            path: 'team',
            name: 'ShopTeam'
          },
          {
            component: () => import(/* webpackChunkName: "ShopEdit" */ './time-sheets/List'),
            path: 'time-sheets',
            name: 'ShopTimeSheets'
          }
        ]
      },
      {
        component: () => import(/* webpackChunkName: "ShopList" */ './shops/List.vue'),
        path: '/shops',
        name: 'ShopList'
      },
      {
        component: () => import(/* webpackChunkName: "ShopAdd" */ './kpis/List.vue'),
        path: '/kpis',
        meta: { title: 'KPIs' },
        name: 'KPIsList'
      }
    ]
  },
  {
    path: '/sign-up',
    name: 'unlicensed',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ './Unlicensed.vue')
  }
]

const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
  authenticationGuard(to, from, next)
})

export default router
