import { valueFromKey } from '@/utils'

export class Dto {
  ActionNeeded
  Attempts
  Comments
  CalledAt
  CreatedAt
  CustomerID
  EmailReviewLinkToCustomer
  EmailShopImmediately
  FirstName
  HeadsUp
  Highlight
  ID
  LastName
  NegativeFeedback
  Opportunity
  RepairOrderCompletedAt
  RepairOrderID
  RepairOrderStatus
  RepairOrderTotal
  RepairOrderType
  Result
  ReviewCommitted
  ReviewRequested
  ScheduledAppointment
  Sentiment
  ServiceAdvisorID
  ServiceAdvisorFirstName
  ServiceAdvisorLastName
  ShopID
  UpdatedAt
  VehicleID

  constructor () {
    this.ActionNeeded = null
    this.Attempts = null
    this.CalledAt = null
    this.Comments = null
    this.CreatedAt = null
    this.CustomerID = null
    this.EmailReviewLinkToCustomer = null
    this.EmailShopImmediately = null
    this.FirstName = null
    this.ID = null
    this.HeadsUp = null
    this.Highlight = null
    this.LastName = null
    this.NegativeFeedback = null
    this.Opportunity = null
    this.Result = null
    this.RepairOrderCompletedAt = null
    this.RepairOrderID = null
    this.RepairOrderStatus = null
    this.RepairOrderTotal = null
    this.RepairOrderType = null
    this.ReviewCommitted = null
    this.ReviewRequested = null
    this.ScheduledAppointment = null
    this.Sentiment = null
    this.ServiceAdvisorID = null
    this.ServiceAdvisorFirstName = null
    this.ServiceAdvisorLastName = null
    this.ShopID = null
    this.UpdatedAt = null
    this.VehicleID = null
  }

  static fromApi (arg) {
    const result = new Dto()
    result.ActionNeeded = valueFromKey('ActionNeeded', arg)
    result.Attempts = valueFromKey('Attempts', arg)
    result.CalledAt = valueFromKey('CalledAt', arg)
    result.Comments = valueFromKey('Comments', arg)
    result.CustomerID = valueFromKey('CustomerID', arg)
    result.EmailReviewLinkToCustomer = valueFromKey('EmailReviewLinkToCustomer', arg)
    result.EmailShopImmediately = valueFromKey('EmailShopImmediately', arg)
    result.CreatedAt = valueFromKey('CreatedAt', arg)
    result.FirstName = valueFromKey('FirstName', arg)
    result.HeadsUp = valueFromKey('HeadsUp', arg)
    result.Highlight = valueFromKey('Highlight', arg)
    result.ID = valueFromKey('ID', arg)
    result.LastName = valueFromKey('LastName', arg)
    result.NegativeFeedback = valueFromKey('NegativeFeedback', arg)
    result.UpdatedAt = valueFromKey('UpdatedAt', arg)
    result.Highlight = valueFromKey('Highlight', arg)
    result.Opportunity = valueFromKey('Opportunity', arg)
    result.Result = valueFromKey('Result', arg)
    result.RepairOrderCompletedAt = valueFromKey('RepairOrderCompletedAt', arg)
    result.RepairOrderID = valueFromKey('RepairOrderID', arg)
    result.RepairOrderStatus = valueFromKey('RepairOrderStatus', arg)
    result.RepairOrderTotal = valueFromKey('RepairOrderTotal', arg)
    result.RepairOrderType = valueFromKey('RepairOrderType', arg)
    result.ReviewCommitted = valueFromKey('ReviewCommitted', arg)
    result.ReviewRequested = valueFromKey('ReviewRequested', arg)
    result.ScheduledAppointment = valueFromKey('ScheduledAppointment', arg)
    result.Sentiment = valueFromKey('Sentiment', arg)
    result.ServiceAdvisorID = valueFromKey('ServiceAdvisorID', arg)
    result.ServiceAdvisorFirstName = valueFromKey('ServiceAdvisorFirstName', arg)
    result.ServiceAdvisorLastName = valueFromKey('ServiceAdvisorLastName', arg)
    result.ShopID = valueFromKey('ShopID', arg)
    result.VehicleID = valueFromKey('VehicleID', arg)
    return result
  }
}

export class MonthFilter {
  /** @type {Date} */
  first

  /** @type {Number} */
  id

  /** @type {Date} */
  last

  /** @type {String} */
  name

  constructor () {
    this.first = null
    this.id = null
    this.last = null
    this.name = null
  }
}
